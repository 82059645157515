
import {Component, Vue, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import CandidateModalCommandMixin from "@/components/ats/Candidate/CandidateModalCommandMixin.vue";
import CandidateProfileDisplay from "@/components/ats/Candidate/CandidateProfileDisplay.vue";
import { IMessageTemplateDB, StoreAction } from '@/types';
import { Action } from 'vuex-class';
import { MessageTemplateType } from '@/data';

@Component({
    name: "SendMessageModal",
    components: { CandidateProfileDisplay }
})
export default class SendMessageModal extends mixins(CandidateModalCommandMixin) {
    name = "SendMessageModal";
    @Prop() loading!: boolean;
    @Action("actions/getMessagesTemplates") getMessagesTemplates!: StoreAction;

    templates: Array<IMessageTemplateDB> = [];
    message:String = "";

    get templateOptions() {
        return [
            { label: "", value: "" },
            ...this.templates.map(template => {
                return { label: template.name, value: template.text };
            })
        ]
    }

    async init() {
        try {
            this.templates = await this.getMessagesTemplates({ type: MessageTemplateType.ats_agency_message }) || [];
        } catch (e) {
            console.error(e);
        }
    }

    onValidate() {
        this.validate(this.errorHandling);
    }

    errorHandling(e:any) {
        this.error = e.errors.candidate;
    }

    onChange() {
        this.error = null;
    }

    beforeMount() {
        this.init();
    }
}
