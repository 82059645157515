
import {Component, Vue, Prop} from 'vue-property-decorator';
import moment from 'moment-timezone';
import {CandidateCommand, CandidateLogType, CandidateStatus, MeetingResult, ROUTES} from "@/data";
import {toastError} from "@/helpers/commons";
import {showModal} from "@/helpers/callables";
import {Action, Getter} from "vuex-class";
import {ICandidateDB, StoreAction} from "@/types";
import DateSchedulingModal from "@/components/ats/modal/DateSchedulingModal.vue";
import ToMeetModal from "@/components/ats/modal/ToMeetModal.vue";
import AfterMeetingModal from "@/components/ats/modal/AfterMeetingModal.vue";
import SendMessageModal from "@/components/ats/modal/SendMessageModal.vue";
import IntegrationModal from "@/components/ats/modal/IntegrationModal.vue";
import RejectionModal from "@/components/ats/modal/RejectionModal.vue";
import NoteAgencyModal from "@/components/ats/modal/NoteAgencyModal.vue"
import NewIcon from '@/components/NewIcon.vue';

@Component({
    name: "CandidateTileAvailableCommands",
    components: {RejectionModal, IntegrationModal, SendMessageModal, AfterMeetingModal, ToMeetModal, DateSchedulingModal, NewIcon, NoteAgencyModal}
})
export default class CandidateTileAvailableCommands extends Vue {
    @Prop() readonly isFollowing!: boolean
    @Prop() readonly candidate!  : ICandidateDB;
    @Prop() readonly context!    : string;

    loading:boolean = false;
    showDateSchedulingModal:boolean = false;
    showSendMessageModal:boolean = false;
    showToMeetModal:boolean = false;
    showAfterMeetingModal:boolean = false;
    showIntegrationModal:boolean = false;
    showRejectionModal:boolean = false;
    showNoteAgencyModal:boolean = false;
    globalErrors:any = [];

    moment = moment;

    @Getter('currentCollaborator') currentCollaborator!: any;
    @Action('actions/sendCommandCandidate') sendCommandCandidate!: StoreAction;

    get displayableCommands(): {name: string, values: CandidateCommand[]}[] {

        const commands = Object.entries(this.candidate.availableCommands).map((rawGroup) => {
            const commandGroupName = rawGroup[0];
            const commandGroupValues: CandidateCommand[] = rawGroup[1] as CandidateCommand[];
            return {
                name: commandGroupName,
                values: commandGroupValues.filter((command: string) => command !== CandidateCommand.update)
            };
        });

        return commands;
    }

    get isFollowingCandidate() {
        return this.context === "followingCandidate";
    }

    get isLastLogContact() {
        if(this.candidate.candidateLogs && this.candidate.status === CandidateStatus.interesting) {
            return this.candidate.candidateLogs[0].type === CandidateLogType.contact;
        }
    }

    get isLastLogVoiceMessage() {
        if(this.candidate.candidateLogs && this.candidate.status === CandidateStatus.interesting) {
            return this.candidate.candidateLogs[0].type === CandidateLogType.voiceMessage;
        }
    }

    doCommand(command: string) {
        switch (command) {
            case CandidateCommand.interested:
                this.displayInterested();
                break;
            case CandidateCommand.reject:
                this.displayReject();
                break;
            case CandidateCommand.scheduleMeeting:
                this.displayDateScheduling();
                break;
            case CandidateCommand.integrate:
                this.displayIntegrate();
                break;
            case CandidateCommand.sendMessage:
                this.displaySendMessage();
                break;
            case CandidateCommand.meetingResult:
                this.displayFeedback();
                break;
            case CandidateCommand.noteAgency:
                this.displayNoteAgency();
                break;
            case CandidateCommand.immediateMeeting:
                this.submitImmediateMeeting();
                break;
            case CandidateCommand.contact:
                this.submitContacted(this.currentCollaborator);
                break;
            case CandidateCommand.voiceMessage:
                this.submitVocalMessage(this.currentCollaborator);
                break;
            default:
                this.sendCommand(command);
                break;
        }
        this.$emit('close');
    }

    label(command: string) {
        return this.$t('candidate.availableCommands.' + command + ".text");
    }

    displayDateScheduling() {
        showModal.call(this, 'DateSchedulingModal');
    }

    displayInterested() {
        showModal.call(this, 'ToMeetModal');
    }

    displayIntegrate() {
        showModal.call(this, 'IntegrationModal');
    }

    displayReject(rejectByCandidate:boolean = false) {
        showModal.call(this, 'RejectionModal');
    }

    displayFeedback() {
        showModal.call(this, 'AfterMeetingModal');
    }

    displayNoteAgency() {
        showModal.call(this, 'NoteAgencyModal');
    }

    displaySendMessage() {
        if (this.isFollowingCandidate) {
            this.$emit('sendMessage');
        } else {
            showModal.call(this, 'SendMessageModal');
        }
    }

    async sendCommand(command:string, data?:any, modalName?:string, modalCallback?: any) {
        this.loading = true;
        try {
            const result = await this.sendCommandCandidate({
                candidateId: this.candidate._id,
                command,
                data
            });

            if (modalName) this.$modal.hide(modalName);

            if(data?.meetingResult === MeetingResult.integrate) {
                this.$emit("candidateChanged", { command: CandidateCommand.integrate, result, req: data });
                return;
            }

            this.$emit("candidateChanged", { command, result, req: data });
        } catch (e) {
            console.error(e);
            if(e.errors.candidate.id === "cant.integrate.candidate") {
                this.$toast.open({
                    message: "Il faut compléter tous les champs obligatoires pour intégrer un candidat au vivier",
                    type: "error",
                    duration: 10000
                });
                this.$router.push({name: ROUTES.ATS.CANDIDATE._ROOT, query: {tabIndex: '1'}, params: {candidateId: this.candidate._id, notCompletedMandatoryFields: e.errors.candidate.data}});
            }
            if (modalCallback) modalCallback(e);
            this.loading = false;
        }
        this.loading = false;
    }

    async submitInterested(data: any) {
        try {
            this.loading = true;
            await this.sendCommand(CandidateCommand.interested, data, data.name);
            this.$emit('forceRender');
            this.loading = false;
        } catch(e) {
            this.$toast.open({
                message: this.$t('errors.Global.ServerError._default') as string,
                type: "error",
                duration: 10000
            });
            this.loading = false;
        }
    }

    async submitReject(data:any, modalCallback?: any) {
        try {
            this.loading = true;
            let requestData:any = { collaboratorId: this.currentCollaborator._id }

            requestData['candidateMessage'] = data.message;
            requestData['comment'] = data.comment;
            await this.sendCommand(CandidateCommand.reject, requestData, data.name,  modalCallback);
            this.$emit('forceRender');
            this.loading = false;
        } catch(e) {
            this.$toast.open({
                message: this.$t('errors.Global.ServerError._default') as string,
                type: "error",
                duration: 10000
            });
            this.loading = false;
        }
    }

    async submitIntegrate(data: any, modalCallback?: any) {
        try {
            this.loading = true;
            await this.sendCommand(CandidateCommand.integrate, { placed: data.placed }, data.name, modalCallback);
            this.$emit('forceRender');
            this.loading = false;
        } catch(e) {
            this.$toast.open({
                message: this.$t('errors.Global.ServerError._default') as string,
                type: "error",
                duration: 10000
            });
            this.loading = false;
        }
    }

    async submitDateSchedule(data: any, modalCallback?: any) {
        try {
            this.loading = true;
            await this.sendCommand(CandidateCommand.scheduleMeeting, {
                collaboratorId: data.collaborator,
                date: data.period.date,
                start: data.period.from,
                end: data.period.to,
                candidateMessage: data.candidateMessage,
                collaboratorMessage: data.comment
            }, data.name, modalCallback);
            this.loading = false;
            this.$emit('forceRender');
        } catch (e) {
            this.$toast.open({
                message: this.$t('errors.Global.ServerError._default') as string,
                type: "error",
                duration: 10000
            });
            this.loading = false;
        }
    }

    async submitFeedback(data: any) {
        try {
            this.loading = true;
            await this.sendCommand(CandidateCommand.meetingResult, { interview: data.interview._id, comment: data.comment, meetingResult: data.meetingResult }, data.name)
            this.$emit('forceRender');
            this.loading = false;
        } catch(e) {
            this.$toast.open({
                message: this.$t('errors.Global.ServerError._default') as string,
                type: "error",
                duration: 10000
            });
            this.loading = false;
        }
    }

    async submitNoteAgency(data: any) {
        await this.sendCommand(CandidateCommand.noteAgency, {data}, data.name)
        this.$emit('forceRender');
    }

    async submitMessage(data: any, modalCallback?:any) {
        try {
            this.loading = true
            await this.sendCommand(CandidateCommand.sendMessage, { collaboratorId: this.currentCollaborator._id, message: data.message }, data.name,  modalCallback);
            this.$emit('forceRender');
            this.loading = false;
        } catch(e) {
            this.$toast.open({
                message: this.$t('errors.Global.ServerError._default') as string,
                type: "error",
                duration: 10000
            });
            this.loading = false;
        }
    }

    async submitImmediateMeeting() {
        try {
            this.loading = true;
            const date = moment(Date.now());
            const period = {
                date: moment.now(),
                start: parseInt(date.format("HHmm"))
            }
            await this.sendCommand(CandidateCommand.immediateMeeting, {
                collaboratorId: this.currentCollaborator._id,
                date: period.date,
                start: period.start,
            });
            this.loading = false;
        } catch(e) {
            this.$toast.open({
                message: this.$t('errors.Global.ServerError._default') as string,
                type: "error",
                duration: 10000
            });
            this.loading = false;
        }
    }

    async submitContacted(user: any) {
        await this.sendCommand(CandidateCommand.contact, user);
        this.$emit('forceRender');
    }

    async submitVocalMessage(user: any) {
        await this.sendCommand(CandidateCommand.voiceMessage, user);
        this.$emit('forceRender');
    }

}
