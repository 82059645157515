
import { Component, Vue, Prop } from 'vue-property-decorator';
import {ICandidateDB, StoreAction} from "@/types";
import {CandidateCommand, ROUTES} from "@/data";
import {showModal} from "@/helpers/callables";
import {Action, Getter} from "vuex-class";
import DateSchedulingModal from "@/components/ats/modal/DateSchedulingModal.vue";
import SendMessageModal from "@/components/ats/modal/SendMessageModal.vue";
import ToMeetModal from '@/components/ats/modal/ToMeetModal.vue';
import AfterMeetingModal from '@/components/ats/modal/AfterMeetingModal.vue';
import IntegrationModal from '@/components/ats/modal/IntegrationModal.vue';
import RejectionModal from '@/components/ats/modal/RejectionModal.vue';
import {toastError} from "@/helpers/commons";
import CandidateTileAvailableCommands from "@/components/ats/Candidate/CandidateTileAvailableCommands.vue";
import NewIcon from '@/components/NewIcon.vue';

@Component({
    name: "CandidateAvailableCommands",
    components: {
        CandidateTileAvailableCommands,
        NewIcon
    }
})
export default class CandidateAvailableCommands extends Vue {
    @Prop() readonly candidate!: ICandidateDB;
    @Prop(String) readonly icon!: string;

    isOpen = false;
    $refs = {}

    get displayableCommands() {
        return Object.entries(this.candidate.availableCommands).length > 0;
    }

    candidateChanged(candidate: ICandidateDB) {
        this.$emit('candidateChanged', candidate);
    }

    close() {
        console.log(this.$refs);

        // @ts-ignore
        this.$refs.popover.hide();
        this.isOpen = false;
    }
}
